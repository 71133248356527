import React, { memo } from "react"
import Layout from "../components/Layout/index"
import SEO from "../components/SEO"
import AboutUsHeader from "../components/Headers/AboutUsHeader3"
import AboutUs from "../containers/AboutUs"

interface AboutUsPageProps {}

const AboutUsPage = memo((props: AboutUsPageProps) => {
  return (
    <Layout title="About Us">
      <AboutUsHeader />
      <SEO title="About us" />
      <AboutUs />
    </Layout>
  )
})

export default AboutUsPage
