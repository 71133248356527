import React, { memo } from "react"
import { Container, Box } from "../../components/ui"
import { Row, Col } from "reactstrap"
// import * as classes from "./styles.module.scss"

interface Props {}

const AboutUs = memo((props: Props) => {
  return (
    <Box paddingY={5}>
      <div className="features-3">
        <Container>
          <Row>
            <Col className="mr-auto ml-auto" md="8">
              <h2 className="title">What do we do?</h2>
              <h4 className="description">Leighton Electrical</h4>
            </Col>
          </Row>
        </Container>
      </div>
    </Box>
  )
})

export default AboutUs
