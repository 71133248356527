import React, { memo } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Col } from "reactstrap"

const AboutUsHeader = memo(() => {
  let pageHeader = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)"
      }
      window.addEventListener("scroll", updateScroll)
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll)
      }
    }
  })

  return (
    <>
      <div className="page-header page-header-small">
        <div className="page-header-image" ref={pageHeader}>
          <StaticImage
            style={{ width: "150%", height: "100%" }}
            src="../../assets/img/about-us.webp"
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="Necta Assist"
          />
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="title">About Us</h1>
              <h4>Leighton Electrical</h4>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
})

export default AboutUsHeader
